import "./App.css";
import Clauroset from "./Components/Clauroset";
import SideBar from "./Components/SideBar";
import Projects from "./Components/Projects";
import Profile from "./Components/Profile";

function App()
{
  return(
    <div className = "main">
      <SideBar />
      <div className = "main_contents">
        <Clauroset />
        <Profile />
        <br/>
      </div>
    </div>
  );
}

export default App;