import "./Clauroset.css";
import { motion } from "framer-motion";

function Clauroset()
{
    return(
        <div className = "carousel_container">
            <motion.div className = "slider" 
                        initial = {{ x : "-100vw", y : "0px" }}
                        animate = {{ x : ["0vw", "0vw", "-100vw", "-100vw", "-200vw", "-200vw", "0vw"], y : "0px" }}
                        transition = {{ ease : "easeInOut", duration : 10, times : [0, 0.2, 0.3, 0.5, 0.6, 0.8, 1] }}
            >
                <div className = "image">
                    <img src = "/Resources/RPlayer.jpg" alt = "err" />
                    <button className = "map" style = {{ backgroundColor : "#12E193" }} >Listen to RPlayer</button>
                </div>
                <div className = "image">
                    <img src = "/Resources/RStock.jpg" alt = "err" />
                    <button className = "map" style = {{ backgroundColor : "#00CED1" }} >Invest on RStock</button>
                </div>
                <div className = "image">
                    <img src = "/Resources/REdit.jpg" alt = "err" />
                    <button className = "map" style = {{ backgroundColor : "#A26DCB" }} >Try exploring REdit</button>
                </div>
            </motion.div>
            <div className = "counter">
                <motion.div className = "count" initial = {{ opacity : null }} animate = {{ opacity : [1, 1, 0.65, 0.65, 1] }} transition = {{ duration : 10, times : [0, 0.3, 0.4, 0.95, 1] }} ></motion.div>
                <motion.div className = "count" initial = {{ opacity : null }} animate = {{ opacity : [0.65, 0.65, 1, 1, 0.65, 0.65] }} transition = {{ duration : 10, times : [0, 0.3, 0.4, 0.7, 0.8, 1] }} ></motion.div>
                <motion.div className = "count" initial = {{ opacity : null }} animate = {{ opacity : [0.65, 0.65, 1, 1, 1, 0.65] }} transition = {{ duration : 10, times : [0, 0.7, 0.8, 0.9, 0.95, 1] }} ></motion.div>
            </div>
        </div>
    );
}

export default Clauroset;