import "./Profile.css";

function Profile()
{
    return(
        <>
            <div className = "profile_desktop">
                <div className = "content_block">
                    <h1>About Us</h1>
                    <div className = "border" style = {{ border : "2px solid #26e726" }}></div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                    <div className = "content">
                        <div className = "image">
                            <img src = "/Resources/About_US.png" alt = "err" />
                        </div>
                        <div className = "script">
                            <span style = {{ fontWeight : "bold", fontSize : "28px" }}>RAGAN INDUSTRIES</span> provide a comprehensive range of IT and IT-enabled services, including web, standalone, and mobile application development, application migration and revamp, data conversion and migration, XML processing, process automation tools, application maintenance, and testing services. Additionally, we specialize in technical publication applications, content development, book publication applications, and learning platform development with content creation.
                            <br/>
                            Our company is supported by a team of highly skilled and dedicated IT professionals with extensive experience across various industries. Our experts, each with over 10 years of experience in their respective domains, have made significant contributions to digital transformations across sectors.
                            <br/>
                            Our experienced technical support team is available around the clock to meet your most complex needs at any time of day or night.
                        </div>
                    </div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                    <div className = "border" style = {{ border : "2px solid #0E87CC" }}></div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                </div>
                <div className = "spacer" style = {{ height : "40px" }} ></div>
                <div className = "content_block">
                    <h1>Our Offerings</h1>
                    <div className = "border" style = {{ border : "2px solid #26e726" }}></div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                    <div className = "content">
                        <div className = "script">
                        We are the leader in offering dedicated developers and programmers, available to work exclusively on your projects, whether on a full-time, part-time, hourly, or contractual basis from our development outsourcing center in Chennai, India. Utilizing our services is highly advantageous for those seeking business expansion without the burden of infrastructure costs or increased overhead.
                        <br/>
                        We provide qualified staff who work solely for you, along with the ability to manage your virtual offshore employees independently. Our company is fully committed to 100% customer satisfaction, and our dedication to quality is unmatched.
                        </div>
                        <div className = "image">
                            <img src = "/Resources/Our_Offerings.png" alt = "err" />
                        </div>
                    </div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                    <div className = "border" style = {{ border : "2px solid #0E87CC" }}></div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                </div>
                <div className = "spacer" style = {{ height : "40px" }} ></div>
                <div className = "content_block">
                    <h1>Our Vision & Mission</h1>
                    <div className = "border" style = {{ border : "2px solid #26e726" }}></div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                    <div className = "content">
                        <div className = "image">
                            <img src = "/Resources/Vision_Mission.png" alt = "err" />
                        </div>
                        <div className = "script">
                        We actively partner with our channel partners to provide end-to-end solutions for our customers, ensuring a wide array of services under one umbrella. Our tagline, <span style = {{ fontWeight : "bold", fontSize : "28px" }}>'SPREAD WINGS WITH US'</span>, signifies our dedication to collaboration and developing win-win solutions for everyone.
                        <br/>
                        We are a rapidly growing technology company dedicated to assisting global organizations in effectively addressing their business challenges through our Global Network Delivery Model, Innovation Network, and Solution Accelerators. We consistently invest in advanced technologies, processes, and talent to drive our customers' success. By fostering innovative ideas through our R&D initiatives and academic partnerships, and by leveraging the expertise of key collaborators, we ensure that our clients remain at the cutting edge of technological advancements.
                        <br/>
                        We aim to deliver innovative solutions through a simplified model within a specified timeframe, ensuring a strong return on investment for both our customers and partners. Our vision is rooted in <span style = {{ fontWeight : "bold", fontSize : "28px" }}>INNOVATION</span>, <span style = {{ fontWeight : "bold", fontSize : "28px" }}>SIMPLICITY</span>, and <span style = {{ fontWeight : "bold", fontSize : "28px" }}>RAPIDITY</span>. Our solutions are designed to enhance the quality of existing processes, save time, and optimize resources.
                        </div>
                    </div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                    <div className = "border" style = {{ border : "2px solid #0E87CC" }}></div>
                    <div className = "spacer" style = {{ height : "20px" }} ></div>
                </div>
            </div>
            <div className = "profile_mobile">

            </div>
        </>
    );
}

export default Profile;