import { useEffect } from "react";
import "./SideBar.css";
import { useState } from "react";

function SideBar()
{
    const [sidebarstatus, setSideBarStatus] = useState(false);

    function toggleSideBar()
    {
        setSideBarStatus(!sidebarstatus);
    }

    return(
        <div>
            <div className = "sidebar_desktop">
                <div className = "sidebar_01" style = {{ display : sidebarstatus ? "none" : "block" }} >
                    <br/>
                    <img src = "/Resources/Bars.png" alt = "err" className = "icons_bars" onClick = { toggleSideBar } />
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Login.png" alt = "err" className = "icons" />
                    </div>
                    <br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Signin.png" alt = "err" className = "icons" />
                    </div>
                    <br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Testimonials.png" alt = "err" className = "icons" />
                    </div>
                    <br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Contact.png" alt = "err" className = "icons" />
                    </div>
                </div>
                <div className = "sidebar_02" style = {{ display : !sidebarstatus ? "none" : "block" }} >
                    <br/>
                    <img src = "/Resources/Bars.png" alt = "err" className = "icons_bars" onClick = { toggleSideBar } />
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Login.png" alt = "err" className = "icons" />
                        &emsp;
                        <h1>Login</h1>
                    </div>
                    <br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Signin.png" alt = "err" className = "icons" />
                        &emsp;
                        <h1>Signin</h1>
                    </div>
                    <br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Testimonials.png" alt = "err" className = "icons" />
                        &emsp;
                        <h1>Testimonials</h1>
                    </div>
                    <br/><br/>
                    <div className = "icon_container">
                        <img src = "/Resources/Contact.png" alt = "err" className = "icons" />
                        &emsp;
                        <h1>Contact</h1>
                    </div>
                </div>
            </div>
            <div className = "sidebar_mobile">
                <div className = "sidebar">
                    <div className = "controls">
                        <img src = "/Resources/Login.png" alt = "err" />
                        &emsp;&emsp;
                        <img src = "/Resources/Signin.png" alt = "err" />
                        &emsp;&emsp;
                        <img src = "/Resources/Testimonials.png" alt = "err" />
                        &emsp;&emsp;
                        <img src = "/Resources/Contact.png" alt = "err" />
                        &emsp;&emsp;
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SideBar;